import React, { useState } from "react";
import { LIST_INSTITUTION } from "../../../Utils/Constant";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import InsititutionList from "./InsititutionList";
import { useDispatch } from "react-redux";
import { setInstView } from "../../../Redux/SettingsSlice";
import useFetchData from "../../../Hooks/useFetchData";
import { useSelector } from "react-redux";
import ShimmerTable from "../../../Components/useShimmerTable";
import { useTranslation } from "react-i18next";

const Institution = () => {
  const [instutionView, setInstutionView] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let payload = {
    limit: 10,
    offset: 1,
  };

  const { loading, error } = useFetchData(
    LIST_INSTITUTION,
    (data) => {
      setInstutionView(data?.menu_array || []);
      dispatch(setInstView(data?.menu_array || []));
    },
    setInstutionView,
    payload
  );

  const handleNavigation = (path) => {
    navigate(`/body/${path}`);
  };

  return (
    <div className="p-6 flex flex-col font-roboto">
      {!error && (
        <div className="flex justify-between items-center">
          <div className="font-roboto font-bold text-lg flex">{t("Institution.inst")}</div>
          <button
            className="bg-color-light-blue hover:bg-color-blue flex items-center p-2 text-nowrap rounded text-color-white w-20 h-10 font-roboto"
            onClick={() => handleNavigation("addinsititution")}
          >
            <FaPlus className="mr-1" />
            {t("Institution.add")}
          </button>
        </div>
      )}

      {loading ? (
        <ShimmerTable />
      ) : (
        <>
          {error ? (
            <p className="text-color-red flex flex-col items-center">{error}</p>
          ) : (
            <InsititutionList instutionView={instutionView} />
          )}
        </>
      )}
    </div>
  );
};

export default Institution;
