import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ServiceForm = ({ selectedDepartment, onSubmit, isSubmitting }) => {
  const selectedDepart = useSelector((store) => store.user.selectedDepartView);
  const [departName, setDepartName] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedDepartment) {
      setDepartName(selectedDepartment.name || "");
    } else {
      setDepartName("");
    }
  }, [selectedDepartment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      dept_id: selectedDepartment?.id,
      dept_name: departName,
      inst_id: selectedDepart?.inst_id,
      status: selectedDepart?.status,
    };
    onSubmit(payload);
    console.log("Submitting payload:", payload);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="flex flex-col p-10 font-roboto w-full sm:w-3/4 flex-nowrap">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="instName" className="mb-2">
              {t("department_name")}
            </label>
            <input
              type="text"
              id="instName"
              value={departName}
              onChange={(e) => setDepartName(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("department_name_placeholder")}
              required
            />
          </div>
        </div>

        <div className="fixed bottom-5 left-auto right-0 flex justify-center gap-2 px-4">
          <button
            type="submit"
            className="bg-color-light-blue text-color-white py-2 px-4 rounded hover:bg-color-blue"
            disabled={isSubmitting}
          >
            {isSubmitting ? t("submitting") : t("submit")}
          </button>
          <button
            type="button"
            onClick={() => navigate("/body/department")}
            className="bg-color-light-blue text-color-white py-2 px-6 rounded hover:bg-color-blue"
          >
            {t("back")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ServiceForm;
