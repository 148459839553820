import React, { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchBarComp = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    console.log("Searching for:", searchQuery);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", margin: "20px" }}>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ width: "200px" }}
      />
      <IconButton
        color="primary"
        onClick={handleSearch}
        style={{ marginLeft: "8px" }}
      >
        <SearchIcon />
      </IconButton>
    </div>
  );
};

export default SearchBarComp;
