import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { BiSolidLock } from "react-icons/bi";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const LoginForm = ({ onSubmit, errors }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { t, i18n } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ userName, password });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  return (
    <div className="flex">
      {/* Left Side - Image & Text Section */}

      {/* Right Side - Login Form Section */}
      <div className="w-1/2 bg-white flex items-center justify-center rounded-tl-3xl">
        <div className="w-3/4 max-w-md">
          <div className="absolute top-4 right-4 font-roboto ">
            <select
              onChange={handleLanguageChange}
              value={i18n.language}
              className="border p-2 rounded outline-none"
            >
              <option value="en">English</option>
              <option value="ar">Arabic</option>
              <option value="fr">French</option>
              <option value="hi">Hindi</option>
            </select>
          </div>
          <form onSubmit={handleSubmit} className="pt-16 font-poppins">
            <div className="flex flex-col  pt-4">
              <label className="pr-[88%]  font-medium text-base text-nowrap items-start">
                {t("user_id_label")}
                <span className="text-color-red">*</span>
              </label>
              <div className="relative w-full h-12 lg:w-96  sm:w-auto ">
                <input
                  type="text"
                  placeholder={t("user_placeholder")}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className="border  w-full h-full rounded pl-10 pr-4 py-2 mt-2 text-nowrap outline-none bg-slate-200"
                />
                <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600 mt-2">
                  <FaUser size={15} />
                </div>
              </div>
              {errors.userName && (
                <p className="text-red-500 text-sm mt-2 flex text-nowrap">
                  {t(`userName_error`)}
                </p>
              )}
            </div>

            <div className="flex flex-col pt-4">
              <label className="pr-[88%] font-medium text-base text-nowrap">
                {t("password_label")} <span className="text-color-red">*</span>
              </label>
              <div className="relative w-full h-12 lg:w-96  sm:w-auto">
                <input
                  type={showPassword ? "text" : "password"} // Toggle input type between password and text
                  placeholder={t("password_placeholder")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className=" border  w-full h-full rounded pl-10 pr-10 py-2 mt-2 text-nowrap outline-none bg-slate-200"
                />
                <div className="absolute  left-3 top-1/2 transform -translate-y-1/2 text-gray-600 mt-2">
                  <BiSolidLock size={20} />
                </div>

                <div
                  className="absolute  right-5 top-1/2 transform mt-2 -translate-y-1/2 text-color-dark-gray cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FiEye size={15} /> : <FiEyeOff size={15} />}
                </div>
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm mt-2 flex">
                  {t(`password_error`)}
                </p>
              )}
            </div>

            <div className="mt-8 flex  w-full h-12 lg:w-96  sm:w-auto">
              <button
                type="submit"
                className="border bg-color-blue rounded shadow-lg w-full lg:w-96 h-12 text-white text-nowrap"
              >
                {t("login_button")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
