import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../../Redux/UserManagementSlice";
import Modal from "./LoginPop";
import LoginForm from "./LoginForm";
import { setToken } from "../../Redux/AuthToken";
import { LOGIN_URL } from "../../Utils/Constant";
import { useTranslation } from "react-i18next";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const { isAuthenticated, user, loading, error } = useSelector(
  //     (state) => state.login
  //   ); // Get state from Redux
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { t, i18n } = useTranslation();

  const validateForm = (userName, password) => {
    let isValid = true;
    let errors = {};

    if (!userName) {
      errors.userName = "User ID is required";
      isValid = false;
    }

    if (!password) {
      errors.password = "Password is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const loginUser = async ({ userName, password }) => {
    if (!validateForm(userName, password)) return;

    try {
      //let API_URL = API_URL;
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "X-Password": "4970FAB298E271E430010235E9C88EA5E467DEEF",
          "X-Username": "wallet",
          Appversion: "4.0.4",
          "Content-Type": "application/json",
          Deviceid: "1234545667676",
        },
        body: JSON.stringify({
          user_name: userName,
          password,
          user_type: "CORPORATE",
        }),
      });

      const data = await response.json();
      console.log("loginData", data);
      console.log(data.token_info.jwt_token);

      if (response.ok && data.code === 1) {
        dispatch(userData(data));
        dispatch(setToken(data.token_info.jwt_token));
        navigate("/body");
      } else {
        setErrorMessage("Invalid username or password. Please try again.");
        setShowModal(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setShowModal(true);
    }
  };

  return (
    <div className="flex flex-col sm:flex-row h-screen">
      {/* Left section (Form) */}

      {/* Right section (Image and Background) */}
      <div className="hidden sm:flex flex-col justify-center items-center w-[50%] bg-gradient-to-r from-gray-700 to-gray-800 p-10 relative">
        <div className="text-center">
          {/* <img
            src="your-image-url-here" // Replace with the doctor's image URL
            alt="Doctor"
            className="rounded-full w-40 h-40 mx-auto mb-4"
          /> */}
          <h1 className="text-4xl font-bold"> {t("login_title")}</h1>
          <p className="mt-4">{t("login_subtitle")}</p>
        </div>
        <footer className="absolute bottom-4 text-sm">
          {t("footer_title")}
        </footer>
      </div>

      <div className="flex justify-center items-center w-full sm:w-[50%] p-8">
        <div className="bg-white p-10 w-full max-w-lg">
          <h1 className="font-roboto font-bold text-4xl text-gray-800 mb-4">
            {t("rlogin_title")}
          </h1>
          <p className="text-gray-500 mb-8">{t("rlogin_subtitle")}</p>

          <LoginForm onSubmit={loginUser} errors={errors} />
        </div>
      </div>

      {/* Modal for errors */}
      {showModal && (
        <Modal message={errorMessage} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default Login;
