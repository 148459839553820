import React from "react";
import { useSelector } from "react-redux";
import InsititutionForm from "./InsititutionForm";
import { EDIT_INSTITUTION } from "../../../Utils/Constant";
import useEdit from "../../../Hooks/useEdit";

const EditInsititution = () => {
  const selectedInstitution = useSelector(
    (store) => store.user.selectedinstView
  );
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);

  const { handleEditSubmit, isSubmitting, error, successMessage } =
    useEdit(EDIT_INSTITUTION);

  const handleSubmit = (payload) => {
    handleEditSubmit(payload, token, deviceId, "/body/institution");
  };

  console.log(handleSubmit);
  return (
    <div>
      <InsititutionForm
        onSubmit={handleSubmit}
        selectedInstitution={selectedInstitution}
        isSubmitting={isSubmitting}
      />
      {error && <p className="text-color-red">{error}</p>}
      {successMessage && <p className="text-color-green">{successMessage}</p>}
    </div>
  );
};

export default EditInsititution;
