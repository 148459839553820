import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
//import userReducer from "./SettingsSlice";
import { loadState, saveState } from "./Middleware";
import AuthToken from "./AuthToken";
import settingsReducer from "./SettingsSlice";
import userManagementReducer from "./UserManagementSlice";

// Load the persisted state
const preloadedState = loadState();

const Store = configureStore({
  reducer: {
    // user: userReducer,
    token: AuthToken,
    setting: settingsReducer,
    userManagement: userManagementReducer,
  },
  preloadedState, // Set the preloaded state
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Use the named import
});

//Save the state to localStorage whenever it changes
Store.subscribe(() => {
  saveState(Store.getState());
});

export default Store;
