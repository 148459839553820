import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useDelete = (deleteUrl, deleteAuthUrl) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleDelete = async (
    payload,
    token,
    deviceId,
    userId,
    selectedAccount,
    successRedirectUrl
  ) => {
    setIsDeleting(true);
    setError(null);
    setSuccessMessage(null);

    try {
      // Decide which API to call based on the condition
      const API_URL =
        selectedAccount.process_status === 4 &&
        !userId.includes(selectedAccount.created_userid)
          ? deleteAuthUrl
          : deleteUrl;

      const response = await fetch(API_URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok || data.code === 0) {
        throw new Error(`Failed to deauthorize: ${data.message}`);
      }
      setSuccessMessage(data.message || "Deauth is successful!");
      setTimeout(() => navigate(successRedirectUrl), 50);
    } catch (error) {
      setError(error.message || "Failed to delete");
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    } else {
      toast.error(error);
    }
  }, [successMessage, error]);

  return {
    handleDelete,
    isDeleting,
    error,
    successMessage,
  };
};

export default useDelete;
