const KeyValueDisplay = ({ data }) => {
  const capitalizeWords = (string) => {
    return string
      ?.split(/[\s_]/)
      ?.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  };

  return (
    <div>
      {Object.entries(data)?.map(([key, value]) => (
        <div key={key} className="flex justify-between p-1 ">
          <strong className="w-1/3 text-left">
            {capitalizeWords(key.replace(/_/g, " "))}:
          </strong>
          <span className="w-2/3 text-right">{value || "N/A"}</span>
        </div>
      ))}
    </div>
  );
};

export default KeyValueDisplay;
