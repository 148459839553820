import React, { useState } from "react";
import DropdownMenuAccountSub from "../../../Components/UserDropdownMenu";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedBranchView } from "../../../Redux/SettingsSlice";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../SearchBar";
import DropdownFilter from "../../DropdownFilter";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import useSortIcon from "../../useSortIcon";

const BranchList = ({ branch}) => {
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedInst, setSelectedBranch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterStatus, setFilterStatus] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // let payload = {
  //   limit: rowsPerPage,
  //   offset: currentPage,
  // };

  //const actions = useSelector((store) => store.instaEnroll.globalActions);
  const user = useSelector((store) => store?.user?.items);
  const userId = user?.map((items) => items.user_id);
  // const { loading, error } = useFetchData(payload);

  const staticActions = [
    { label: "View", action: "view" },
    { label: "Edit", action: "edit" },
    { label: "Delete", action: "delete" },
  ];

  const toggleDropdown = (index, branchItem) => {
    setOpenDropdowns({
      [index]: !openDropdowns[index],
    });
    setSelectedBranch(branchItem);
  };

  const closeDropdown = (index) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  const openBranchViewPage = (branchItem, index) => {
    dispatch(setSelectedBranchView(branchItem));
    toggleDropdown(index, branchItem); // Close the dropdown
    navigate("/body/viewbranch");
  };

  

  const openAccountSubEditPage = (branchItem) => {
    dispatch(setSelectedBranchView(branchItem));
    navigate(`/body/editbranch/${branchItem.id}`);
  };

  const openAccountSubDeletePage = (branchItem) => {
    dispatch(setSelectedBranchView(branchItem));
    navigate(`/body/deletebranch/${branchItem.id}`);
  };

  //const headers = accountSub?.[0] ? Object.keys(accountSub[0]) : [];

  // Get all headers dynamically from the first accountSub item
  const finalHeaders = branch?.find((obj) => {
    // Check if any key in the object has an "_edited" version
    return Object.keys(obj).some((key) => key.includes("_editted"));
  });

  // If an edited field exists, use the entire object, otherwise fallback to the first object
  const headersToUse = finalHeaders ? finalHeaders : branch?.[0];

  const headerKeys = finalHeaders
    ? Object.keys(finalHeaders) // If we found the edited object, get its keys
    : headersToUse
    ? Object.keys(headersToUse)
    : [];

    const headerKeyMap = {
      id: "BranchPage.id",
      email: "BranchPage.email",
      Branch: "BranchPage.Branch",
      name: "BranchPage.name",
      inst_id: "BranchPage.inst_id",
      inst_name:"BranchPage.inst_name",
      status: "BranchPage.status",
      address: "BranchPage.address",
      mobile: "BranchPage.mobile",
      created_time: "BranchPage.created_time",
      created_by: "BranchPage.created_by",
      actions: "BranchPage.actions",
    };
    

  const sortedAccounts = [...branch].sort((a, b) => {
    if (sortColumn) {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Filter accounts based on the search query
  const filteredAccountSubType = sortedAccounts?.filter((accountSubItem) => {
    const matchesSearchQuery =
      accountSubItem.id
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      accountSubItem.subtype_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      accountSubItem.description
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

    const matchesFilterStatus =
      !filterStatus || accountSubItem.auth_status === filterStatus;

    return matchesSearchQuery && matchesFilterStatus;
  });

  const handleSearchChange = (query) => setSearchQuery(query);
  const handleFilterChange = (status) => setFilterStatus(status);

  // Pagination logic
  const totalAccounts = filteredAccountSubType.length;
  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedAccounts = filteredAccountSubType.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setRowsPerPage(pageSize);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const SortIconComponent = (column) => {
    const SortIcon = useSortIcon(sortColumn, sortOrder, column);
    return <SortIcon />;
  };

  return (
    <div className="bg-color-white border shadow-md p-2 mt-2 rounded-md font-roboto text-nowrap">
      <div className="mt-2 flex flex-col ">
        <div className="flex justify-between items-center mb-4">
          <div className="relative flex items-center">
            <SearchBar
              placeholder={t("BranchPage.search_profile")}
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
            />
          </div>
          <DropdownFilter
            selectedValue={filterStatus}
            onFilterChange={handleFilterChange}
          />
        </div>

        {filteredAccountSubType.length === 0 ? (
          <div className="text-center text-color-dark-gray my-4">
            {t("no data")}
          </div>
        ) : (
          <div className="relative w-full  max-h-[28rem] overflow-x-auto scrollbar scrollbar-thumb-scrollbar-color-gray  scrollbar-thumb-rounded-full scrollbar-w-2 ">
            <table className="w-full divide-y divide-color-gray-200 table-fixed">
              <div>
                <thead className="bg-color-dim-gray">
                  <tr>
                    {headerKeys.map((header, index) => (
                      <th
                        key={index}
                        onClick={() => handleSort(header)}
                        className="text-base font-bold border text-color-header-dark-gray tracking-wider p-4 min-w-[190px] sticky top-0 bg-color-dim-gray z-10 "
                      >
                        <div className="flex justify-center items-center">
                        {t(headerKeyMap[header] || header)} 
                          <span>{SortIconComponent(header)}</span>
                        </div>
                      </th>
                    ))}
                    <th className="text-base font-bold border text-color-header-dark-gray tracking-wider w-32 px-8 sticky top-0 bg-color-dim-gray z-10 right-0">
                      {t("BranchPage.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody >
                  {paginatedAccounts.map((branchItem, index) => (
                    <tr key={branchItem.id}>
                      {headerKeys.map((header, i) => (
                        <td
                          key={i}
                          className="text-nowrap border text-sm text-color-dark-gray p-4 min-w-[190px]"
                        >
                          {branchItem[header]}
                        </td>
                      ))}

                      <td className="whitespace-nowrap text-sm border text-color-dark-gray bg-color-white sticky top-0 right-0  ">
                        <DropdownMenuAccountSub
                          index={index}
                          item={branchItem}
                          openDropdowns={openDropdowns}
                          toggleDropdown={toggleDropdown}
                          closeDropdown={closeDropdown}
                          actions={staticActions}
                          onEdit={openAccountSubEditPage}
                         
                          onView={openBranchViewPage}
                          onDelete={openAccountSubDeletePage}
                          userId={userId}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
          </div>
        )}

        <Pagination
          size="small"
          current={currentPage}
          total={totalAccounts}
          pageSize={rowsPerPage}
          showSizeChanger
          showQuickJumper
          onChange={handlePageChange}
          showTotal={(total) => t("BranchPage.totalRows", { count: total })}
          className="mt-4 ml-auto font-roboto"
        />
      </div>
    </div>
  );
};

export default BranchList;
