import React from "react";
import BranchForm from "./BranchForm";
import { ADD_BRANCH } from "../../../Utils/Constant";
import useAdd from "../../../Hooks/useAdd";

const AddBranch = () => {
  const { handleAddSubmit, isSubmitting, error, successMessage } = useAdd(
    ADD_BRANCH,
    "/body/branch"
  );

  return (
    <div>
      <BranchForm onSubmit={handleAddSubmit} isSubmitting={isSubmitting} />
      {error && <p className="text-color-red">{error}</p>}
      {successMessage && <p className="text-color-green">{successMessage}</p>}
    </div>
  );
};

export default AddBranch;
