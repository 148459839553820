import React from "react";
import ServiceForm from "./ServiceForm";
import { ADD_DEPARTMENT } from "../../../Utils/Constant";
import useAdd from "../../../Hooks/useAdd";
//import { useSelector } from "react-redux";

const AddService = () => {
  // const selectedDepartment = useSelector(
  //   (store) => store.user.selectedDepartView
  // );

  const { handleAddSubmit, isSubmitting, error, successMessage } = useAdd(
    ADD_DEPARTMENT,
    "/body/department"
  );

  console.log(handleAddSubmit);
  return (
    <div>
      <ServiceForm onSubmit={handleAddSubmit} isSubmitting={isSubmitting} />
      {error && <p className="text-color-red">{error}</p>}
      {successMessage && <p className="text-color-green">{successMessage}</p>}
    </div>
  );
};

export default AddService;
