import React, { useState, useEffect, useRef } from "react";
import flag from "../Assets/flag.png";
import USFlag from "../Assets/English.png";
import Arab from "../Assets/Arab.png";
import france from "../Assets/france.png";
import { useTranslation } from "react-i18next";

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const [selectedFlag, setSelectedFlag] = useState(USFlag);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const languages = [
    { name: "Indian", flag: flag, code: "hi" },
    { name: "English", flag: USFlag, code: "en" },
    { name: "French", flag: france, code: "fr" },
    { name: "Arabic", flag: Arab, code: "ar" },
  ];

  const handleSelect = (language) => {
    setSelectedFlag(language.flag); // Update flag
    i18n.changeLanguage(language.code); // Change language using i18n
    setIsDropdownOpen(false); // Close dropdown
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex items-center space-x-4 cursor-pointer"
        onClick={toggleDropdown}
      >
        <img src={selectedFlag} alt="Selected Flag" className="w-8 h-8" />
      </div>
      {isDropdownOpen && (
        <div className="absolute mt-2 bg-white right-5 top-14 shadow-sm rounded-md ">
          <ul>
            {languages.map((lang, index) => (
              <li
                key={index}
                className="flex items-center w-32 p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSelect(lang)}
              >
                <img
                  src={lang.flag}
                  alt={`${lang.name} Flag`}
                  className="w-8 h-8 "
                />
                <span className="ml-2">{lang.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
