import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "setting",
  initialState: {
    items: [],
    isCollapse: false,
    instView: [],
    departView: [],
    selectedinstView: [],
    branchView: [],
    selectedbranchView: [],
    selectedDepartView: [],
    serView: [],
    selectedServiceView: [],
    isMobile: false,
  },
  reducers: {
    userData: (state, action) => {
      state.items.push(action.payload); // Add payload to items array
    },
    setIsCollapse: (state, action) => {
      state.isCollapse = !state.isCollapse;
    },
    setIsMobile: (state, action) => {
      state.isMobile = !state.isMobile;
    },
    setInstView: (state, action) => {
      state.instView = action.payload; // Add payload menuInf0 to items array
    },
    setBranchView: (state, action) => {
      state.branchView = action.payload; // Add payload menuInf0 to items array
    },
    setSelectedBranchView: (state, action) => {
      state.selectedbranchView = action.payload; // Update state correctly
    },

    setDepartView: (state, action) => {
      state.departView = action.payload; // Add payload menuInf0 to items array
    },
    setSelectedInstView: (state, action) => {
      state.selectedinstView = action.payload; // Add payload menuInf0 to items array
    },
    setSelectedDepartView: (state, action) => {
      state.selectedDepartView = action.payload; // Add payload menuInf0 to items array
    },
    setSerView: (state, action) => {
      state.serView = action.payload; // Add payload menuInf0 to items array
    },
    setSelectedServicetView: (state, action) => {
      state.selectedServiceView = action.payload; // Add payload menuInf0 to items array
    },

    // Action to clear the user data when logging out
    clearUserData: (state) => {
      state.user = [];
      state.items = [];
      state.isCollapse = false;
      state.instView = [];
      state.departView = [];
      state.selectedinstView = [];
      state.branchView = [];
      state.selectedbranchView = [];
      state.selectedDepartView = [];
      state.serView = [];
      state.isMobile = false;
    },
  },
});

// Export the actions for use in components
export const {
  userData,
  setIsCollapse,
  setInstView,
  setSelectedInstView,
  setDepartView,
  setBranchView,
  setSelectedBranchView,
  setSelectedDepartView,
  setSelectedServicetView,
  setSerView,
  setIsMobile,
} = settingsSlice.actions;

// Export the reducer to be used in the store
export default settingsSlice.reducer;
