import React, { useState, useEffect, useRef } from "react";
import { FaRegUser, FaCog } from "react-icons/fa";
import { RiDashboardLine } from "react-icons/ri";
import { MdToken } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoLogoFirefox } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
//import { setIsMobile } from "../../Redux/SettingsSlice";

const Sidebar = () => {
  const isSidebarCollapsed = useSelector((store) => store.setting.isCollapse);
  //const isSidebarMobile = useSelector((store) => store.setting.isMobile);

  const userType = useSelector((store) => store.userManagement.items.user_type);

  const [activeSection, setActiveSection] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation();
  const sidebarRef = useRef(null);

  const toggleSection = (section) => {
    setActiveSection((prev) => (prev === section ? null : section));
  };

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setActiveSection(null); // Close the pop-out menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //sidebar menus:

  const SidebarContent1 = () => (
    <>
      <div
        className={`font-monserrat px-5 pt-6 mb-5 flex items-center ${
          isSidebarCollapsed ? "justify-center" : ""
        }`}
      >
        <Link to="/body" className="flex items-center cursor-pointer">
          <IoLogoFirefox
            className={`w-[32px] h-[32px] text-gray-600 ${
              isSidebarCollapsed ? "mr-0 justify-center" : "mr-4"
            }`}
          />
          <span
            className={`text-gray-700 font-bold text-md ${
              isSidebarCollapsed ? "hidden" : "block"
            }`}
          >
            {t("logo_title")}
          </span>
        </Link>
      </div>
      <div className="mb-5 group px-8">
        {/* Dashboard Section */}
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "dashboard"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          <Link to="/body" className="flex items-center cursor-pointer">
            <RiDashboardLine className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("dashboard")}
            </span>
          </Link>
        </div>
      </div>

      {/* User Section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "user" ? "bg-color-dim-blue p-2 rounded-lg" : ""
          }`}
          onClick={() => toggleSection("user")}
        >
          <div className="flex items-center cursor-pointer text-nowrap">
            <FaRegUser className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("user_management")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "user" ? (
          // Pop-Out Menu when Sidebar is Collapsed
          <div className="absolute left-20 top-32 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="profile"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("profile")}
            </Link>
            <Link
              to="user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("user")}
            </Link>
            <Link
              to="user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("password_policy")}
            </Link>
          </div>
        ) : (
          // Normal Inline Menu when Sidebar is Expanded
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "user" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="profile"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("profile")}
            </Link>
            <Link
              to="user"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("user")}
            </Link>
            <Link
              to="user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("password_policy")}
            </Link>
          </div>
        )}
      </div>

      {/* Settings Section */}
      <div className="mb-5 group px-8">
        {/* Settings Section Header */}
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "settings"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => toggleSection("settings")}
        >
          <div className="flex items-center cursor-pointer">
            <FaCog className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("settings")}
            </span>
          </div>
        </div>

        {/* Settings Section Links */}
        {isSidebarCollapsed && activeSection === "settings" ? (
          // Pop-Out Menu when Sidebar is Collapsed

          <div className="absolute left-20 top-44 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="institution"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("institution")}
            </Link>
            <Link
              to="data_retention"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("data_retention")}
            </Link>
            <Link
              to="language"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("language")}
            </Link>
          </div>
        ) : (
          // Normal Inline Menu when Sidebar is Expanded
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "settings" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="institution"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("institution")}
            </Link>
            <Link
              to="branch"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("data_retention")}
            </Link>
            <Link
              to="language"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("language")}
            </Link>
          </div>
        )}
      </div>
    </>
  );

  const SidebarContent2 = () => (
    <>
      <div
        className={`font-monserrat px-5 pt-6 mb-5 flex items-center ${
          isSidebarCollapsed ? "justify-center" : ""
        }`}
      >
        <Link to="/body" className="flex items-center cursor-pointer">
          <IoLogoFirefox
            className={`w-[32px] h-[32px] text-gray-600 ${
              isSidebarCollapsed ? "mr-0 justify-center" : "mr-4"
            }`}
          />
          <span
            className={`text-gray-700 font-bold text-md ${
              isSidebarCollapsed ? "hidden" : "block"
            }`}
          >
            {t("logo_title")}
          </span>
        </Link>
      </div>
      <div className="mb-5 group px-8">
        {/* Dashboard Section */}
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "dashboard"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          <Link to="/body" className="flex items-center cursor-pointer">
            <RiDashboardLine className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("dashboard")}
            </span>
          </Link>
        </div>
      </div>

      {/* User Section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "user" ? "bg-color-dim-blue p-2 rounded-lg" : ""
          }`}
          onClick={() => toggleSection("user")}
        >
          <div className="flex items-center cursor-pointer text-nowrap">
            <FaRegUser className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("user_management")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "user" ? (
          // Pop-Out Menu when Sidebar is Collapsed
          <div className="absolute left-20 top-32 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="profile"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("profile")}
            </Link>
            <Link
              to="user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("user")}
            </Link>
            <Link
              to="user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("password_policy")}
            </Link>
          </div>
        ) : (
          // Normal Inline Menu when Sidebar is Expanded
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "user" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="profile"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("profile")}
            </Link>
            <Link
              to="user"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("user")}
            </Link>
            <Link
              to="user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("password_policy")}
            </Link>
          </div>
        )}
      </div>

      {/* Settings Section */}
      <div className="mb-5 group px-8">
        {/* Settings Section Header */}
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "settings"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => toggleSection("settings")}
        >
          <div className="flex items-center cursor-pointer">
            <FaCog className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("settings")}
            </span>
          </div>
        </div>

        {/* Settings Section Links */}
        {isSidebarCollapsed && activeSection === "settings" ? (
          // Pop-Out Menu when Sidebar is Collapsed

          <div className="absolute left-20 top-44 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="branch"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("branch")}
            </Link>
            <Link
              to="department"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("department")}
            </Link>
            <Link
              to="service"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("service")}
            </Link>

            <Link
              to="counter"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("counter")}
            </Link>
          </div>
        ) : (
          // Normal Inline Menu when Sidebar is Expanded
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "settings" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="branch"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("branch")}
            </Link>
            <Link
              to="department"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("department")}
            </Link>
            <Link
              to="service"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("service")}
            </Link>
            <Link
              to="counter"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("counter")}
            </Link>
          </div>
        )}
      </div>
    </>
  );

  const SidebarContent3 = () => (
    <>
      <div
        className={`font-monserrat px-5 pt-6 mb-5 flex items-center ${
          isSidebarCollapsed ? "justify-center" : ""
        }`}
      >
        <Link to="/body" className="flex items-center cursor-pointer">
          <IoLogoFirefox
            className={`w-[32px] h-[32px] text-gray-600 ${
              isSidebarCollapsed ? "mr-0 justify-center" : "mr-4"
            }`}
          />
          <span
            className={`text-gray-700 font-bold text-md ${
              isSidebarCollapsed ? "hidden" : "block"
            }`}
          >
            {t("logo_title")}
          </span>
        </Link>
      </div>
      <div className="mb-5 group px-8">
        {/* Dashboard Section */}
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "dashboard"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          <Link to="/body" className="flex items-center cursor-pointer">
            <RiDashboardLine className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("dashboard")}
            </span>
          </Link>
        </div>
      </div>

      {/* User Section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "user" ? "bg-color-dim-blue p-2 rounded-lg" : ""
          }`}
          onClick={() => toggleSection("user")}
        >
          <div className="flex items-center cursor-pointer text-nowrap">
            <FaRegUser className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("user_management")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "user" ? (
          // Pop-Out Menu when Sidebar is Collapsed
          <div className="absolute left-20 top-32 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="profile"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("profile")}
            </Link>
            <Link
              to="user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("user")}
            </Link>
            <Link
              to="branch_user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("branch_user")}
            </Link>

            <Link
              to="link_user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("link_user")}
            </Link>
          </div>
        ) : (
          // Normal Inline Menu when Sidebar is Expanded
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "user" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="profile"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("profile")}
            </Link>
            <Link
              to="user"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("user")}
            </Link>
            <Link
              to="branch_user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("branch_user")}
            </Link>

            <Link
              to="link_user"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("link_user")}
            </Link>
          </div>
        )}
      </div>

      {/* Settings Section */}
      <div className="mb-5 group px-8">
        {/* Settings Section Header */}
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "settings"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => toggleSection("settings")}
        >
          <div className="flex items-center cursor-pointer">
            <FaCog className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("settings")}
            </span>
          </div>
        </div>

        {/* Settings Section Links */}
        {isSidebarCollapsed && activeSection === "settings" ? (
          // Pop-Out Menu when Sidebar is Collapsed

          <div className="absolute left-20 top-44 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="department"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("department")}
            </Link>
            <Link
              to="service"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("service")}
            </Link>

            <Link
              to="counter"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("counter")}
            </Link>
            <Link
              to="token"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
              onClick={() => setActiveSection(null)}
            >
              {t("token")}
            </Link>
          </div>
        ) : (
          // Normal Inline Menu when Sidebar is Expanded
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "settings" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="branch"
              className="p-2 cursor-pointer block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("branch")}
            </Link>
            <Link
              to="department"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("department")}
            </Link>
            <Link
              to="service"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("service")}
            </Link>
            <Link
              to="counter"
              className="p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg"
            >
              {t("counter")}
            </Link>
          </div>
        )}
      </div>
    </>
  );

  const SidebarContent4 = () => (
    <>
      <div
        className={`font-monserrat px-5 pt-6 mb-5 flex items-center ${
          isSidebarCollapsed ? "justify-center" : ""
        }`}
      >
        <Link to="/body" className="flex items-center cursor-pointer">
          <IoLogoFirefox
            className={`w-[32px] h-[32px] text-gray-600 ${
              isSidebarCollapsed ? "mr-0 justify-center" : "mr-4"
            }`}
          />
          <span
            className={`text-gray-700 font-bold text-md ${
              isSidebarCollapsed ? "hidden" : "block"
            }`}
          >
            {t("logo_title")}
          </span>
        </Link>
      </div>
      <div className="mb-5 group px-8">
        {/* Dashboard Section */}
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "dashboard"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          <Link to="/body" className="flex items-center cursor-pointer">
            <RiDashboardLine className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("dashboard")}
            </span>
          </Link>
        </div>
      </div>

      {/* User Section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "token_management"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("token_management")}
        >
          <Link
            to="/body/tokenmanagement"
            className="flex items-center cursor-pointer"
          >
            <MdToken className="w-5 h-5 text-gray-600 mr-3" />
            <span
              className={`text-gray-700 ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              {t("token_management")}
            </span>
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {isMobile ? (
        <div>
          <button
            className="p-3 fixed top-2 left-2  rounded-md z-50"
            onClick={toggleDrawer}
          >
            <AiOutlineMenu size={24} />
          </button>
          {isDrawerOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={toggleDrawer}
            ></div>
          )}
          <div
            className={`fixed top-0 left-0 h-screen bg-white shadow-lg z-50 transition-transform transform ${
              isDrawerOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            {userType === 1 && <SidebarContent1 />}
            {userType === 2 && <SidebarContent2 />}
            {userType === 3 && <SidebarContent3 />}
            {userType === 4 && <SidebarContent4 />}
          </div>
        </div>
      ) : (
        <div
          ref={sidebarRef}
          className={`transition-all duration-300 flex flex-col ${
            isSidebarCollapsed ? "w-20" : "w-[17rem]"
          } h-screen bg-white shadow-lg`}
        >
          {userType === 1 && <SidebarContent1 />}
          {userType === 2 && <SidebarContent2 />}
          {userType === 3 && <SidebarContent3 />}
          {userType === 4 && <SidebarContent4 />}
        </div>
      )}
    </div>
  );
};

export default Sidebar;