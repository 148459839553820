import React, { useState } from "react";
import { LIST_DEPARTMENT } from "../../../Utils/Constant";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DepartmentList from "./DepartmentList";
import { useDispatch } from "react-redux";
import { setDepartView } from "../../../Redux/SettingsSlice";
import useFetchData from "../../../Hooks/useFetchData";
import { useSelector } from "react-redux";
import ShimmerTable from "../../useShimmerTable";
import { useTranslation } from "react-i18next";

const Department = () => {
  const [departmentView, setDepartmentView] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let payload = {
    page: 1,
    no_of_data: 40,
    inst_id: departmentView.inst_id,
    keyword: "",
    user_based_list: true,
    order_by: 0,
    inst_details: false,
  };

  const { loading, error } = useFetchData(
    LIST_DEPARTMENT,
    (data) => {
      setDepartmentView(data?.data || []);
      dispatch(setDepartView(data?.data || []));
    },
    setDepartmentView,
    payload
  );

  const handleNavigation = (path) => {
    navigate(`/body/${path}`);
  };

  return (
    <div className="p-6 flex flex-col font-roboto">
      {!error && (
        <div className="flex justify-between items-center">
          <div className="font-roboto font-bold text-lg flex">
            {t("Department.department")}
          </div>
          <button
            className="bg-color-light-blue hover:bg-color-blue flex items-center p-2 text-nowrap rounded text-color-white w-20 h-10 font-roboto"
            onClick={() => handleNavigation("adddepartment")}
          >
            <FaPlus className="mr-1" />
            {t("Department.add")}
          </button>
        </div>
      )}

      {loading ? (
        <ShimmerTable />
      ) : (
        <>
          {error ? (
            <p className="text-color-red flex flex-col items-center">{error}</p>
          ) : (
            <DepartmentList departmentView={departmentView} />
          )}
        </>
      )}
    </div>
  );
};

export default Department;
