import React from "react";
import Header from "./Pages/Header/header";
import Sidebar from "./Pages/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const Body = () => {
  const user = useSelector((store) => store.userManagement.items);

  if (!user) return null;
  return (
    <div className="flex flex-row bg-color-dimligt-blue">
      <Sidebar />
      <div className="w-full">
 <Header loginData={user}/>

        <Outlet />
      </div>
    </div>
  );
};

export default Body;
