import React, { useState } from "react";
import { FiMoreVertical } from "react-icons/fi"; // Import an icon for three dots

const DropdownMenu = ({ actions, item, onView, onEdit, onDelete }) => {
  console.log(item);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const closeDropdown = () => setIsOpen(false);

  const handleAction = (action) => {
    if (action === "view") onView(item);
    if (action === "edit") onEdit(item);
    if (action === "delete") onDelete(item);
    closeDropdown(); // Close dropdown after an action is clicked
  };

  return (
    <div className="relative">
      {/* Three-dot icon button */}
      <button
        onClick={toggleDropdown}
        className="p-2 rounded-full hover:bg-gray-200 focus:outline-none"
      >
        <FiMoreVertical className="w-5 h-5 text-gray-700" />
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute right-36 mt-2 w-40 bg-white border rounded-md shadow-lg z-50">
          {actions.map((action) => (
            <button
              key={action.action}
              onClick={() => handleAction(action.action)}
              className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
            >
              {action.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
