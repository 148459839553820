import { createSlice } from "@reduxjs/toolkit";

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    items: null,
    isCollapse: false,
    userView: [],
    selecteduserView: [],
    selecteduserDisplay: [],
    collectusers:[]
  },
  reducers: {
    userData: (state, action) => {
      state.items = action.payload; 
    },
    collectFetchUsers : (state, action) => {
     state.collectusers = action.payload
    },
    setIsCollapse: (state, action) => {
      state.isCollapse = !state.isCollapse;
    },
    setUserView: (state, action) => {
      state.userView = action.payload;
    },
    setSelectedUserView: (state, action) => {
      state.selecteduserView = action.payload;
    },
    setSelectedUserDisplay: (state, action) =>{
      state.selecteduserDisplay = action.payload;
    },

    clearUserData: (state) => {
      state.user = [];
      state.items = null;
      state.isCollapse = false;
      state.userView = [];
      state.selecteduserView = [];
      state.selecteduserDisplay = [];
      state.collectusers = []
    },
  },
});

// Export the actions for use in components
export const { userData, setIsCollapse, setUserView, setSelectedUserView , setSelectedUserDisplay, clearUserData, collectFetchUsers} =
  userManagementSlice.actions;

// Export the reducer to be used in the store
export default userManagementSlice.reducer;
