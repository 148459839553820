import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useEdit = (url) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleEditSubmit = async (
    payload,
    token,
    deviceId,
    successRedirectUrl
  ) => {
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify(payload),
      });
      const responseData = await response.json();

      if (!response.ok || responseData.code === 0) {
        throw new Error(
          responseData.message || `Error: ${response.statusText}`
        );
      }
      setSuccessMessage(responseData.message || "Operation successful!");
      setTimeout(() => navigate(successRedirectUrl), 50);
    } catch (err) {
      setError(err.message || "Failed to edit");
      setTimeout(() => navigate(successRedirectUrl), 50);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    } else {
      toast.error(error);
    }
  }, [successMessage, error]);

  return {
    handleEditSubmit,
    isSubmitting,
    error,
    successMessage,
  };
};

export default useEdit;
