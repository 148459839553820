import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InsititutionForm = ({ selectedInstitution, onSubmit, isSubmitting }) => {
  console.log(selectedInstitution);
  const [instName, setInstName] = useState("");
  const [hoAddress, setHoAddress] = useState("");
  const [hoEmail, setHoEmail] = useState("");
  const [hoAltEmail, setHoAltEmail] = useState("");
  const [hoPhone, setHoPhone] = useState("");
  const [hoAltPhone, setHoAltPhone] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedInstitution) {
      setInstName(selectedInstitution.inst_name || "");
      setHoAddress(selectedInstitution.ho_address || "");
      setHoEmail(selectedInstitution.ho_mailid || "");
      setHoAltEmail(selectedInstitution.ho_alternate_email || "");
      setHoPhone(selectedInstitution.ho_mobile || "");
      setHoAltPhone(selectedInstitution.ho_alternate_mobile || "");
    } else {
      setInstName("");
      setHoAddress("");
      setHoEmail("");
      setHoAltEmail("");
      setHoPhone("");
      setHoAltPhone("");
    }
  }, [selectedInstitution]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      inst_name: instName,
      ho_address: hoAddress,
      ho_email: hoEmail,
      ho_alt_email: hoAltEmail,
      ho_phone: hoPhone,
      ho_alt_phone: hoAltPhone,
      status: 1,
      institution_id: selectedInstitution?.id,
    };
    console.log("Submitting payload:", payload);
    onSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="flex flex-col p-10 font-roboto w-full sm:w-3/4 flex-nowrap">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="instName" className="mb-2">
              {t("Institution.name")}
            </label>
            <input
              type="text"
              id="instName"
              value={instName}
              onChange={(e) => setInstName(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.name_placeholder")}
              required
            />
          </div>
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="hoAddress" className="mb-2">
              {t("Institution.head_office_address")}
            </label>
            <input
              type="text"
              id="hoAddress"
              value={hoAddress}
              onChange={(e) => setHoAddress(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.headOffice_name")}
              required
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="hoEmail" className="mb-2">
              {t("Institution.email")}
            </label>
            <input
              type="email"
              id="hoEmail"
              value={hoEmail}
              onChange={(e) => setHoEmail(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.email_placeholder")}
              required
            />
          </div>
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="hoAltEmail" className="mb-2">
              {t("Institution.alternate_email")}
            </label>
            <input
              type="email"
              id="hoAltEmail"
              value={hoAltEmail}
              onChange={(e) => setHoAltEmail(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.alt_email_placeholder")}
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="hoPhone" className="mb-2">
              {t("Institution.phone")}
            </label>
            <input
              type="tel"
              id="hoPhone"
              value={hoPhone}
              onChange={(e) => setHoPhone(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.phone_placeholder")}
              required
            />
          </div>
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="hoAltPhone" className="mb-2">
              {t("Institution.alternate_phone")}
            </label>
            <input
              type="tel"
              id="hoAltPhone"
              value={hoAltPhone}
              onChange={(e) => setHoAltPhone(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.alt_phone_placeholder")}
            />
          </div>
        </div>

        <div className="fixed bottom-5 left-auto right-0 flex justify-center gap-2 px-4">
          <button
            type="submit"
            className="bg-color-light-blue text-color-white py-2 px-4 rounded hover:bg-color-blue"
            disabled={isSubmitting}
          >
            {isSubmitting ? t("Institution.submitting") : t("Institution.submit")}
          </button>
          <button
            type="button"
            onClick={() => navigate("/body/institution")}
            className="bg-color-light-blue text-color-white py-2 px-6 rounded hover:bg-color-blue"
          >
            {t("Institution.back")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default InsititutionForm;
