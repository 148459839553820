import { TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";

function DataTableHeader({
  columns,
  order,
  orderBy,
  onRequestSort,
  hideBorders,
}) {
  const createSortHandler = (property, isCustomSort) => (event) => {
    onRequestSort(event, property, isCustomSort);
  };

  const renderHeaderCell = (column) => {
    return column.header ? column.header(column) : column.label;
  };

  return (
    <thead>
      <tr>
        {columns.map((column, index) => (
          <th
            key={column.path || column.label || index + "dataTableHeader"}
            style={{
              border: hideBorders ? "none" : "1px solid #ddd",
              fontWeight: "bold",
              textAlign: column.align || "center",  // Flexible alignment
              fontSize: "14px",
              padding: "10px 15px",  // Increased padding for better spacing
              ...column.columnStyle,
            }}
          >
            {column.sortable ? (
              <TableSortLabel
                active={orderBy === column.path}
                direction={orderBy === column.path ? order : "asc"}
                onClick={createSortHandler(column.path, column.isCustomSort)}
              >
                {renderHeaderCell(column)}
                {orderBy === column.path ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              renderHeaderCell(column)
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default DataTableHeader;
