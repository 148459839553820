import React, { useEffect, useState } from "react";

const TokenManagement = () => {
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [serviceTimeElapsed, setServiceTimeElapsed] = useState(0);
  const [currentTokenIndex, setCurrentTokenIndex] = useState(-1);
  const [statusMessage, setStatusMessage] = useState("Ideal Time");
  const [isTokenCalled, setIsTokenCalled] = useState(false);

  // Static list of tokens
  const tokens = ["T001", "T002", "T003", "T004", "T005"];

  // Start the general timer when the page loads
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Timer for servicing tokens
  useEffect(() => {
    let serviceTimer;
    if (statusMessage === "Token is serving") {
      serviceTimer = setInterval(() => {
        setServiceTimeElapsed((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(serviceTimer);
  }, [statusMessage]);

  const handleCallNextToken = () => {
    if (currentTokenIndex < tokens.length - 1) {
      setCurrentTokenIndex((prevIndex) => prevIndex + 1);
      setStatusMessage("Token is ready to be served");
      setServiceTimeElapsed(0);
      setIsTokenCalled(true);
    } else {
      alert("No more tokens in the queue!");
    }
  };

  const handleMarkAsServicing = () => {
    if (currentTokenIndex !== -1) {
      setStatusMessage("Token is serving");
      setServiceTimeElapsed(0);
    } else {
      alert("No token selected!");
    }
  };

  const handleButtonClick = (action) => {
    console.log(`${action} button clicked`);
  };

  // Format time into minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <div className="token-management p-4">
      <h1 className="text-3xl font-bold mb-6 text-center">Token Management</h1>
      
      {/* Timer */}
      {!isTokenCalled && (
        <div className="timer mb-6 text-center">
          <span className="text-6xl font-extrabold text-blue-600">
            {formatTime(timeElapsed)}
          </span>
          <div className="text-lg text-gray-500 mt-2">
            Ideal Time (MM:SS)
          </div>
        </div>
      )}

      {/* Current Token */}
      {currentTokenIndex !== -1 && (
        <div className="current-token text-center mb-6">
          <div 
            className={`
              text-green-500 
              ${isTokenCalled ? 'text-7xl font-extrabold' : 'text-4xl font-bold mt-2'}
            `}
          >
            {tokens[currentTokenIndex]}
          </div>
          <div className="text-lg text-gray-500 mt-2">{statusMessage}</div>
          {statusMessage === "Token is serving" && (
            <div className="text-lg text-gray-700 mt-2">
              Service Time: {formatTime(serviceTimeElapsed)}
            </div>
          )}
        </div>
      )}

      {/* Buttons */}
      <div className="fixed bottom-5 flex justify-center pl-6 gap-2 text-nowrap">
        <button
          className="bg-blue-500 text-white px-6 py-3 text-lg rounded hover:bg-blue-600 outline-none"
          onClick={handleCallNextToken}
        >
          Call Next Token
        </button>
        <button
          className="action-btn bg-blue-500 text-white px-6 py-3 text-lg rounded hover:bg-blue-600 outline-none"
          onClick={handleMarkAsServicing}
        >
          Mark as Serving
        </button>
        {[
          { id: "ReCallToken", label: "Re-Call Token" },
          { id: "HoldToken", label: "Hold Token" },
          { id: "SkipToken", label: "Skip Token" },
          { id: "ResumeHeldToken", label: "Resume Held Token" },
          { id: "TransferToken", label: "Transfer Token" },
        ].map((action) => (
          <button
            key={action.id}
            className="action-btn bg-blue-500 text-white px-6 py-3 text-lg rounded hover:bg-blue-600 outline-none"
            onClick={() => handleButtonClick(action.label)}
          >
            {action.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TokenManagement;