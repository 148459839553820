import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSettings = () => {
  const { i18n, t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  // Available languages
  const availableLanguages = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'Arabic' },
    { code: 'fr', name: 'French' },
    { code: 'hi', name: 'Hindi' }
  ];

  // Change language handler
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    
    // Change language using i18n
    i18n.changeLanguage(selectedLanguage);
    
    // Update local state
    setCurrentLanguage(selectedLanguage);

    // Optional: Store language preference in localStorage
    localStorage.setItem('appLanguage', selectedLanguage);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('appLanguage');
    if (savedLanguage && availableLanguages.some(lang => lang.code === savedLanguage)) {
      i18n.changeLanguage(savedLanguage);
      setCurrentLanguage(savedLanguage);
    }
  }, []);

  return (
    <div className="max-w-md  p-6  rounded-lg ">
      <div className="flex items-center mb-6">
        <span className="text-2xl mr-3">🌐</span>
        <h2 className="text-xl font-semibold text-gray-800">
          {t('languageSettings.title')}
        </h2>
      </div>

      <div className="mb-4">
        <label 
          htmlFor="language-select" 
          className="block mb-2 text-sm font-medium text-gray-700"
        >
          {t('languageSettings.selectLanguage')}
        </label>
        <select 
          id="language-select"
          value={currentLanguage}
          onChange={handleLanguageChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {availableLanguages.map((lang) => (
            <option key={lang.code} value={lang.code}>
              {lang.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-6 p-3 bg-gray-100 rounded-md">
        <div className="text-sm">
          <span className="font-medium text-gray-700">
            {t('languageSettings.currentLanguage')}:
          </span>
          <span className="text-blue-600">
            {availableLanguages.find(lang => lang.code === currentLanguage)?.name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LanguageSettings;