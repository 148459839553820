import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { setDeviceId } from "../../Redux/AuthToken";

const Dashborad = () => {
  //const [deviceId, setDeviceId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      //setDeviceId(result.visitorId);
      dispatch(setDeviceId(result.visitorId));
      //console.log(result.visitorId)
    };

    loadFingerprint();
  }, []);

  return <div>Dashborad</div>;
};

export default Dashborad;
