import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DELETE_SERVICE } from "../../../Utils/Constant";
import useDelete from "../../../Hooks/useDelete";
import KeyValueDisplay from "../../KeyValue";

const DeleteService = () => {
  const selectedService = useSelector(
    (store) => store.user.selectedServiceView
  );
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState("");
  const token = useSelector((store) => store.token.token);

  const user = useSelector((store) => store?.user?.items);
  const userId = user?.map((items) => items.user_id);
  const deviceId = useSelector((store) => store.token.deviceId);

  const { handleDelete, isDeleting, error, successMessage } =
    useDelete(DELETE_SERVICE);

  const handleDeleteClick = () => {
    const payload = {
      id: selectedService.id,
    };

    handleDelete(
      payload,
      token,
      deviceId,
      userId,
      selectedService,
      "/body/service"
    );
  };

  if (!selectedService) {
    return <div>No proof of address data available for deletion.</div>;
  }

  return (
    <div className="px-5 py-4 font-roboto">
      <h2 className="text-xl font-bold mb-4 flex">Confirm Delete</h2>
      <div className="border w-full h-auto mb-5 p-4 rounded-md shadow-md bg-color-white">
        <KeyValueDisplay data={selectedService} />
      </div>

      {/* Feedback Textarea */}
      <div className="mb-4">
        <label
          htmlFor="feedback"
          className="flex text-sm font-medium text-color-black"
        >
          Why are you deleting this account? (optional)
        </label>
        <textarea
          id="feedback"
          rows="4"
          className="mt-1 block w-full p-2 border rounded-md shadow-md outline-none"
          placeholder="Provide a reason for deletion (e.g., incorrect data, no longer needed)"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)} // Set feedback state
        ></textarea>
      </div>

      {/* Buttons */}
      <div className="mb-4 fixed  left-auto right-0 flex justify-end gap-2 px-4 ">
        <button
          onClick={handleDeleteClick}
          className="bg-color-red text-color-white px-4 py-2 rounded mr-4"
          disabled={isDeleting}
        >
          {isDeleting ? "Deleting..." : "Confirm Delete"}
        </button>
        <button
          onClick={() => navigate("/body/department")}
          className="bg-color-dark-gray text-color-white px-4 py-2 rounded"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteService;
