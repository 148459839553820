import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../../CustomReusableComps/InputField";
import { toast } from "react-toastify";
import { EDIT_USER } from "../../../Utils/Constant";
import { setSelectedUserView } from "../../../Redux/UserManagementSlice";
import { FiEye, FiEyeOff } from "react-icons/fi";

const UserForm = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //for institute dropdown:
  const instView = useSelector((state) => state.setting.instView);

  const selectedUserData = useSelector(
    (state) => state?.userManagement?.selecteduserView
  );
  console.log("selectedUserData", selectedUserData);

  const collectedusers = useSelector(
    (state) => state?.userManagement?.collectusers
  );
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userType = useSelector((store) => store.userManagement.items.user_type);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    user_name: "",
    pwd: "",
    mobile_number: "",
    alternate_mob_number: "",
    email: "",
    alternate_email: "",
    inst_id: null,
    user_Type: null,
  });

  useEffect(() => {
    if (!selectedUserData) {
      dispatch(setSelectedUserView(null));
    }
  }, [dispatch, selectedUserData]);

  useEffect(() => {
    console.log("selectedUserData", selectedUserData);

    if (selectedUserData) {
      setFormData({
        id: selectedUserData.id,
        user_name: selectedUserData.username || "",
        pwd: selectedUserData.hash_pwd || "",
        mobile_number: selectedUserData.mobile_number || "",
        alternate_mob_number: selectedUserData.alternate_mob_number || "",
        email: selectedUserData.email || "",
        alternate_email: selectedUserData.alternate_email || "",
        inst_id: selectedUserData.inst_id || null,
        user_type: selectedUserData.user_type || null,
      });
    }
  }, [selectedUserData]);

  const handleEditUser = async (rowData) => {
    if (!rowData || !rowData.id) {
      toast.error("Invalid user data for editing.");
      return;
    }

    try {
      const response = await fetch(EDIT_USER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          id: rowData.id,
          user_name: rowData.user_name || "",
          pwd: rowData.pwd || "",
          mobile_number: rowData.mobile_number || "",
          alternate_mob_number: rowData.alternate_mob_number || "",
          email: rowData.email || "",
          alternate_email: rowData.alternate_email || "",
          inst_id: rowData.inst_id || null,
          user_type: rowData.user_type || null,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success("User updated successfully!");
        dispatch(setSelectedUserView(null));
        setFormData({
          user_name: "",
          pwd: "",
          mobile_number: "",
          alternate_mob_number: "",
          email: "",
          alternate_email: "",
          inst_id: null,
          user_Type: null,
        });
        dispatch(setSelectedUserView(null));
        navigate("/body/user");
      } else {
        toast.error(
          `Failed to update user: ${responseData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("An error occurred while updating the user.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInstitutionChange = (e) => {
    const selectedInstId = parseInt(e.target.value, 10);
    setFormData((prev) => ({
      ...prev,
      inst_id: selectedInstId,
    }));
  };

  const handleUserTypeChange = (e) => {
    const selectedUserType = parseInt(e.target.value, 10);
    setFormData((prev) => ({
      ...prev,
      user_Type: selectedUserType,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const {
    //   user_name,
    //   pwd,
    //   mobile_number,
    //   alternate_mob_number,
    //   email,
    //   alternate_email,
    // } = formData;

    // const isEmailChanged =
    //   email !== selectedUserData?.email ||
    //   alternate_email !== selectedUserData?.alternate_email;
    // const isPhoneChanged =
    //   mobile_number !== selectedUserData?.mobile_number ||
    //   alternate_mob_number !== selectedUserData?.alternate_mob_number;

    // if (
    //   !user_name ||
    //   !pwd ||
    //   !mobile_number ||
    //   !alternate_mob_number ||
    //   !email ||
    //   !alternate_email
    // ) {
    //   toast.error("All fields are required.");
    //   return;
    // }

    // if (isEmailChanged) {
    //   const isEmailDuplicate = collectedusers.some(
    //     (user) =>
    //       user?.email === email ||
    //       user?.alternate_email === email ||
    //       user?.email === alternate_email ||
    //       user?.alternate_email === alternate_email
    //   );
    //   if (isEmailDuplicate) {
    //     toast.error("Email or Alternate Email already exists.");
    //     return;
    //   }
    // }

    // if (isPhoneChanged) {
    //   const isPhoneDuplicate = collectedusers.some(
    //     (user) =>
    //       user?.mobile_number === mobile_number ||
    //       user?.alternate_mob_number === mobile_number ||
    //       user?.mobile_number === alternate_mob_number ||
    //       user?.alternate_mob_number === alternate_mob_number
    //   );
    //   if (isPhoneDuplicate) {
    //     toast.error("Mobile Number or Alternate Mobile Number already exists.");
    //     return;
    //   }
    // }

    if (selectedUserData) {
      handleEditUser(formData);
    } else if (onSubmit) {
      onSubmit(formData);
    }
  };

  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold font-sans">User</h1>
      <div className="mt-10 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <InputField
              name="user_name"
              label={t("User Name")}
              placeholder={t("Enter your username")}
              value={formData.user_name}
              onChange={handleInputChange}
              className="w-full"
            />
            <div className="relative w-full">
              <InputField
                name="pwd"
                label={t("Password")}
                type={showPassword ? "text" : "password"}
                placeholder={t("Enter your password")}
                value={formData.pwd}
                onChange={handleInputChange}
                className="w-full"
              />
              <div
                className="absolute right-3 top-1/2 transform -translate-y-1/7 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FiEye size={20} /> : <FiEyeOff size={20} />}
              </div>
            </div>

            <InputField
              name="mobile_number"
              label={t("Phone Number")}
              placeholder={t("Enter your phone number")}
              value={formData.mobile_number}
              onChange={handleInputChange}
              className="w-full"
            />
            <InputField
              name="alternate_mob_number"
              label={t("Alternate Mobile Number")}
              placeholder={t("Enter alternate phone number")}
              value={formData.alternate_mob_number}
              onChange={handleInputChange}
              className="w-full"
            />
            <InputField
              name="email"
              type="email"
              label={t("Email ID")}
              placeholder={t("Enter your email")}
              value={formData.email}
              onChange={handleInputChange}
              className="w-full"
            />
            <InputField
              name="alternate_email"
              type="email"
              label={t("Alternate Email")}
              placeholder={t("Enter alternate email")}
              value={formData.alternate_email}
              onChange={handleInputChange}
              className="w-full"
            />
            <div className="w-full">
              <label
                htmlFor="institution"
                className="block mb-2 font-medium text-gray-700"
              >
                {t("UserPage.inst_name")}
              </label>
              <select
                id="institution"
                name="inst_id"
                value={formData.inst_id || ""}
                onChange={handleInstitutionChange}
                className="block w-full p-3 border border-gray-300 rounded"
              >
                <option value="" disabled>
                  {t("Select Institution")}
                </option>
                {instView.map((institution) => (
                  <option key={institution.id} value={institution.id}>
                    {institution.inst_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full">
              <label
                htmlFor="userType"
                className="block mb-2 font-medium text-gray-700"
              >
                {t("User Type")}
              </label>
              <select
                id="user_Type"
                name="user_Type"
                value={formData.user_Type || ""}
                onChange={handleUserTypeChange}
                className="block w-full p-3 border border-gray-300 rounded"
              >
                <option value="" disabled>
                  {t("Select User Type")}
                </option>
                {userType === 1 && (
                  <option value="2">{t("Institute Admin")}</option>
                )}
                {userType === 2 && (
                  <option value="3">{t("Branch/Bank Admin")}</option>
                )}
                {userType === 3 && (
                  <option value="4">{t("Counter Admin")}</option>
                )}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8 w-full">
            <button
              type="submit"
              className={`bg-blue-500 text-white py-3 px-6 rounded shadow hover:bg-blue-600 transition w-full ${
                isSubmitting && "opacity-50 cursor-not-allowed"
              }`}
              disabled={isSubmitting}
            >
              {selectedUserData ? t("Update") : t("Submit")}
            </button>
            <button
              type="button"
              onClick={() => navigate("/body/user")}
              className="bg-gray-500 text-white py-3 px-6 rounded shadow hover:bg-gray-600 transition w-full"
            >
              {t("Back")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;
