import React from "react";
import DepartmentForm from "./DepartmentForm";
import { ADD_DEPARTMENT } from "../../../Utils/Constant";
import useAdd from "../../../Hooks/useAdd";
import { useSelector } from "react-redux";

const AddDepartment = () => {
  const { handleAddSubmit, isSubmitting } = useAdd(
    ADD_DEPARTMENT,
    "/body/department"
  );


  return (
    <div>
      <DepartmentForm onSubmit={handleAddSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default AddDepartment;
