export const MAIN_API_URL = "https://api-innovitegra.online";

export const LOGIN_URL = MAIN_API_URL + "/qmsadmin/user/user_login";

//INSITUTION APIs:
export const LIST_INSTITUTION = MAIN_API_URL + "/qmsadmin/inst/view_inst";
export const ADD_INSTITUTION = MAIN_API_URL + "/qmsadmin/inst/add_inst";
export const EDIT_INSTITUTION = MAIN_API_URL + "/qmsadmin/inst/edit_inst";

//DEPAARTMENT APIs:
export const LIST_DEPARTMENT = MAIN_API_URL + "/qmsadmin/department/view_department";
export const ADD_DEPARTMENT = MAIN_API_URL + "/qmsadmin/department/add_department";
export const EDIT_DEPARTMENT = MAIN_API_URL + "/qmsadmin/department/edit_department";
export const DELETE_DEPARTMENT = MAIN_API_URL + "/qmsadmin/department/delete_department";


//SERVICE APIs:
export const LIST_SERVICE = MAIN_API_URL + "/qmsadmin/service/view_services";
export const ADD_SERVICE = MAIN_API_URL + "/qmsadmin/service/add_service";
export const EDIT_SERVICE = MAIN_API_URL + "/qmsadmin/service/edit_service";
export const DELETE_SERVICE = MAIN_API_URL + "/qmsadmin/service/delete_service";

//SuperAdmin Users Creation Apis:
export const ADD_USERS = MAIN_API_URL + "/qmsadmin/user/add_user";
export const USERS_LIST_INADMIN = MAIN_API_URL + "/qmsadmin/user/view_users";
export const USER_DELETE = MAIN_API_URL + "/qmsadmin/user/delete_user";
export const EDIT_USER = MAIN_API_URL + "/qmsadmin/user/edit_user"

//BRANCH APIs:
export const LIST_BRANCH = MAIN_API_URL + "/qmsadmin/branch/view_branches";
export const ADD_BRANCH= MAIN_API_URL + "/qmsadmin/branch/add_branch";
export const EDIT_BRANCH= MAIN_API_URL + "/qmsadmin/branch/edit_branch";
export const DELETE_BRANCH= MAIN_API_URL + "/qmsadmin/branch/delete_branch";