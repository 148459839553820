import React from "react";
import InsititutionForm from "./InsititutionForm";
import { ADD_INSTITUTION } from "../../../Utils/Constant";
import useAdd from "../../../Hooks/useAdd";

const AddInsititution = () => {
  const { handleAddSubmit, isSubmitting, error, successMessage } = useAdd(
    ADD_INSTITUTION,
    "/body/institution"
  );

  return (
    <div>
      <InsititutionForm
        onSubmit={handleAddSubmit}
        isSubmitting={isSubmitting}
      />
      {error && <p className="text-color-red">{error}</p>}
      {successMessage && <p className="text-color-green">{successMessage}</p>}
    </div>
  );
};

export default AddInsititution;
