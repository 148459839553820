import React, { useState, useEffect } from "react";
import Table from "../../CustomReusableComps/Table";
import { Container, Card, IconButton } from "@mui/material";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Button from "../../MUI_Reusable_Comps/Button";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import {USERS_LIST_INADMIN} from '../../../Utils/Constant'
import { useDispatch, useSelector ,} from "react-redux";
import { toast } from "react-toastify";
import { USER_DELETE, } from "../../../Utils/Constant";
import { setSelectedUserView, collectFetchUsers } from "../../../Redux/UserManagementSlice";

const User = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleOpenActions = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    console.log(selectedRow);
  };

 

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(USERS_LIST_INADMIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          user_based_list: true,
          order_by: 1,
          keyword: "",
          inst_details: true,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
      dispatch(collectFetchUsers(responseData.data))
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddUserClick = () => {
    dispatch(setSelectedUserView(null));
    navigate("/body/adduser")
  }

  const handleEdit = (rowData) => {
    dispatch(setSelectedUserView(rowData));
    navigate('/body/createUser')
  };

  const handleView = (rowData) => {  
    dispatch(setSelectedUserView(rowData));
    navigate('/body/viewuser')
  };

  const handleDelete = (rowData) => {
    console.log("Delete clicked for row:", rowData);
    setSelectedRow(rowData);
    handleDeleteUser(rowData);
  };

  const handleDeleteUser = async (rowData) => {
    if (!rowData || !rowData.id) {
      toast.error("Invalid user data for deletion.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(USER_DELETE, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          id: rowData.id,
        }),
      });
      const responseData = await response.json(); // Parse JSON response
      console.log("Delete response:", responseData);

      if (response.ok) {
        fetchData(currentPage, pageSize); // Refresh data after deletion
        toast.success("User deleted successfully!");
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to delete user: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An error occurred while deleting the user.");
    } finally {
      setLoading(false);
      handleCloseActions();
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const headers = [
    { key: "id", label: "#" },
    { key: "username", label: t("UserPage.User_Name") },
    { key: "mobile_number", label: t("UserPage.Contact_Number") },
    { key: "alternate_mob_number", label: "Alternate Mobile Number" },
    { key: "email", label: t("UserPage.Email_Id") },
    { key: "alternate_email", label: t("UserPage.Alter_Email") },
    { key: "inst_name", label: t("Institute Name") },
    { key: "user_type", label: t("UserPage.User_Type") },
    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <IconButton
            onClick={(event) => handleOpenActions(event, rowData)}
            color="primary"
            sx={{ height: "20px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="p-8">
      <div className=" mb-4 flex justify-between items-center">
        <Button name="Add User" onClick={handleAddUserClick} />
        <Breadcrumbs options={[{ name: "Users", pathName: "/body/user" }]} />
      </div>

      <Container>
        <Card>
          <div className="">
            <Table
              data={data || []}
              headers={headers}
              loading={loading}
              searchPlaceholder="Search by name or email"
              filterOptions={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              totalRowsLabel={`Total Rows: ${totalCount}`}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              totalItems={totalCount}
            />
          </div>
        </Card>
      </Container>

      <ActionPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleCloseActions}
        selectedRow={selectedRow}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
      />
    </div>
  );
};

export default User;
