import React from "react";
import { Button, CircularProgress } from "@mui/material";

export default function CustomButton({
  isLoading,
  onClick,
  name,
  disabled,
  fullWidth = false,
  data,
  icon,
  color = "primary",
  type = "button",
  size = "medium",
  variant = "contained",
}) {
  const handleClick = React.useCallback(
    (e) => {
      onClick && onClick(e, data);
    },
    [data, onClick]
  );

  return (
    <Button
      fullWidth={fullWidth}
      onClick={handleClick}
      loading={Boolean(isLoading)}
      color={color}
      startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : icon}
      size={size}
      variant={variant}
      type={type}
      name={name}
      disabled={Boolean(isLoading || disabled)}
    >
      {!isLoading ? name : "Loading..."}
    </Button>
  );
}
