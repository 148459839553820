import React from "react";
import { useState } from "react";
import DataTable from "../../MUI_Reusable_Comps/DataTable";
import { Container, Card, IconButton } from "@mui/material";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Button from "../../MUI_Reusable_Comps/Button";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Profile = () => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = [
    { path: "id", label: "Profile Id" },            
    { path: "ProName", label: "Profile Name" },
    { path: "ProDescription", label: "Profile Description" },
    { path: "ProDesignation", label: "Profile Designation" },
    {
      path: "edit",
      label: "actions",
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (userData) => {
        return (
          <div className="d-flex">
            {/* Edit Button */}
            <IconButton
              color="primary"
          
            >
              <EditIcon />
            </IconButton>
    
            {/* Delete Button */}
            <IconButton
              
            >
              <DeleteIcon color="error" />
            </IconButton>
          </div>
        );
      },
    }
    
    
  ];

  const rows = [
    {
      id: 1,
      ProName: "Admin",
      ProDescription: "N/A",
      ProDesignation: "Admin Role",
    },
    {
      id: 2,
      ProName: "Institute Admin",
      ProDescription: "Institute Admin",
      ProDesignation: "Institute Admin Role",
    },
    {
      id: 3,
      ProName: "Branch Admin",
      ProDescription: "N/A",
      ProDesignation: "Branch Admin Role",
    },
  
  ];

  return (
    <div className="p-8">
      <div className="p-3 flex justify-between items-center">
        <Button 
          name='Add profile' 
          onClick={() => navigate('/body/createUser')} 
        />
        <Breadcrumbs
          options={[
            {
              name: "profile",
              pathName: '/body/profile',
            },
          ]}
        />
      </div>
      
      <Container>
        <Card>
          <div className="pt-2">
            <DataTable
              columns={columns}
              rows={rows}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              setSortColumn={setSortColumn}
              setSortOrder={setSortOrder}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setRowsPerPage}
              hideBorders={false}
              showPagination={true}
              onRowClick={(row) => console.log('Row clicked:', row)}
            />
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default Profile;