import React from "react";
import { useSelector } from "react-redux";
import BranchForm from "./BranchForm";
import { EDIT_BRANCH} from "../../../Utils/Constant";
import useEdit from "../../../Hooks/useEdit";

const EditBranch = () => {
  const selectedBranch= useSelector(
    (store) => store.user.selectedbranchView
  );
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);

  const { handleEditSubmit, isSubmitting, error, successMessage } =
    useEdit(EDIT_BRANCH);

  const handleSubmit = (payload) => {
    handleEditSubmit(payload, token, deviceId, "/body/branch");
  };

  console.log(handleSubmit);
  return (
    <div>
      <BranchForm
        onSubmit={handleSubmit}
        selectedBranch={selectedBranch}
        isSubmitting={isSubmitting}
      />
      {error && <p className="text-color-red">{error}</p>}
      {successMessage && <p className="text-color-green">{successMessage}</p>}
    </div>
  );
};

export default EditBranch;
