import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const DepartmentForm = ({ selectedDepartment, onSubmit, isSubmitting }) => {
  const InstType = useSelector((store) => store.setting.instView);
  console.log(selectedDepartment);

  const [departName, setDepartName] = useState("");
  const [instType, setInstType] = useState("");
  const [status, setStatus] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedDepartment) {
      setDepartName(selectedDepartment.name || "");
      setInstType(selectedDepartment.inst_name || "");
      setStatus(selectedDepartment.status || "");
    } else {
      setDepartName("");
      setInstType("");
      setStatus("");
    }
  }, [selectedDepartment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      dept_id: selectedDepartment?.id,
      dept_name: departName,
      inst_id: selectedDepartment?.inst_id || 1,
      status: selectedDepartment?.status || 1,
    };
    onSubmit(payload);
    console.log("Submitting payload:", payload);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="flex flex-col p-10 font-roboto w-full sm:w-3/4 flex-nowrap">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="instName" className="mb-2">
              {t("Department.name")}
            </label>
            <input
              type="text"
              id="instName"
              value={departName}
              onChange={(e) => setDepartName(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Department.department_name_placeholder")}
              required
            />
          </div>
          <div className="mb-4 flex flex-col justify-start items-start w-full lg:w-80 xl:w-80">
            <label htmlFor="accountType" className="mb-2">
              {t("select_entity_type")}
            </label>
            <select
              id="InstType"
              value={instType}
              onChange={(e) => setInstType(e.target.value)}
              className="w-full border  p-2 rounded outline-none"
            >
              <option value="" disabled>
                {t("select_type_placeholder")}
              </option>
              {InstType?.filter(
                (InstitutionType) => InstitutionType?.inst_name
              )?.map((InstitutionType) => (
                <option key={InstitutionType?.id} value={InstitutionType?.id}>
                  {InstitutionType?.inst_name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col items-start gap-4 sm:gap-4">
            <label>{t("Department.status")}</label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={status === 1}
                onChange={() => setStatus((prev) => (prev === 1 ? 2 : 1))}
              />
              <div
                className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer
                peer-checked:bg-green-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
                after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full
                after:h-5 after:w-5 after:transition-all"
              ></div>
              <span className="ml-3 text-sm font-medium">
                {status === 1
                  ? t("Department.active")
                  : t("Department.inactive")}
              </span>
            </label>
          </div>
        </div>

        <div className="fixed bottom-5 left-auto right-0 flex justify-center gap-2 px-4">
          <button
            type="submit"
            className="bg-color-light-blue text-color-white py-2 px-4 rounded hover:bg-color-blue"
            disabled={isSubmitting}
          >
            {isSubmitting ? t("Deparment.submitting") : t("Department.submit")}
          </button>
          <button
            type="button"
            onClick={() => navigate("/body/department")}
            className="bg-color-light-blue text-color-white py-2 px-6 rounded hover:bg-color-blue"
          >
            {t("Department.back")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default DepartmentForm;
