import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import SearchBar from "../../Components/SearchBar";
import DropdownFilter from "../../Components/DropdownFilter";
import useSortIcon from "../../Components/useSortIcon";

const Table = ({
  data = [],
  headers,
  filterOptions = [],
  rowActions = [],
  searchPlaceholder,
  totalRowsLabel,
  loading,
  currentPage,
  totalItems,
  onPageChange,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterStatus, setFilterStatus] = useState("");
  const { t } = useTranslation();

  const handleSearchChange = (query) => setSearchQuery(query);
  const handleFilterChange = (status) => setFilterStatus(status);
  
  const handlePageChange = (page, pageSize) => {
    if (onPageChange) {
      onPageChange(page, pageSize);
    }
    setRowsPerPage(pageSize);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const SortIconComponent = (column) => {
    const SortIcon = useSortIcon(sortColumn, sortOrder, column);
    return <SortIcon />;
  };

  // Filter data based on searchQuery and filterStatus
  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const matchesSearchQuery = Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const matchesFilterStatus =
        !filterStatus || item.status === filterStatus;

      return matchesSearchQuery && matchesFilterStatus;
    });
  }, [data, searchQuery, filterStatus]);

  return (
    <div className="bg-color-white border shadow-md p-2 mt-2 rounded-md font-roboto text-nowrap">
      <div className="mt-2 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <SearchBar
            placeholder={searchPlaceholder || t("search")}
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
          />
          <DropdownFilter
            selectedValue={filterStatus}
            onFilterChange={handleFilterChange}
            options={filterOptions}
          />
        </div>

        <div className="relative w-full max-h-[27rem] overflow-x-auto scrollbar scrollbar-thumb-scrollbar-color-gray scrollbar-thumb-rounded-full scrollbar-w-2">
          {loading ? (
            <div className="text-center py-4">{t("Loading...")}</div>
          ) : (
            <table className="w-full divide-y divide-color-gray-200 table-auto">
              <thead className="bg-color-dim-gray">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      onClick={() => handleSort(header.key)}
                      className="text-base font-bold border text-color-header-dark-gray tracking-wider p-4 sticky top-0 bg-color-dim-gray z-10"
                      style={
                        header.key === "action"
                          ? { position: "sticky", right: 0 }
                          : {}
                      }
                    >
                      <div className="flex justify-center items-center">
                        {t(header.label)}
                        <span>{SortIconComponent(header.key)}</span>
                      </div>
                    </th>
                  ))}
                  {rowActions.length > 0 && (
                    <th className="text-base font-bold border text-color-header-dark-gray tracking-wider w-32 px-8 sticky top-0 bg-color-dim-gray z-10">
                      {t("UserPage.actions")}
                    </th>
                  )}
                </tr>
              </thead>
              {filteredData.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan={headers.length + (rowActions.length > 0 ? 1 : 0)}
                      className="text-center text-color-dark-gray py-4"
                    >
                      {t("no data")}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.id}>
                      {headers.map((header, i) => (
                        <td
                          key={i}
                          className="text-nowrap border text-sm text-color-dark-gray p-4"
                          style={
                            header.key === "action"
                              ? {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#f3f8fc",
                                  zIndex: 1,
                                }
                              : {}
                          }
                        >
                          {header.content
                            ? header.content(item)
                            : item[header.key]}
                        </td>
                      ))}
                      {rowActions.length > 0 && (
                        <td className="whitespace-nowrap text-sm border text-color-dark-gray bg-color-white sticky top-0 right-0">
                          {rowActions.map((action, actionIndex) => (
                            <button
                              key={actionIndex}
                              onClick={() => action.handler(item)}
                              className="text-sm text-color-link mr-2"
                            >
                              {t(action.label)}
                            </button>
                          ))}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          )}
        </div>

        <Pagination
          size="small"
          current={currentPage}
          total={totalItems} 
          pageSize={rowsPerPage}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '20', '50', '100']}
          onChange={handlePageChange}
          showTotal={(total) => t(totalRowsLabel, { count: total })}
          className="mt-4 ml-auto font-roboto"
        />
      </div>
    </div>
  );
};

export default Table;
