import React, { useState, useRef } from "react";
import { RiMenuFold3Line2, RiSearchLine } from "react-icons/ri";
import { FaRegBell } from "react-icons/fa";
import { setIsCollapse, setIsMobile } from "../../Redux/SettingsSlice";
import { useDispatch } from "react-redux";
import User from "../../Assets/user.jpg";
import { useNavigate } from "react-router-dom";
import flag from "../../Assets/flag.png";
import { useTranslation } from "react-i18next";
import SearchBarComp from "../../Components/MUI_Reusable_Comps/SearchBar";
import LanguageDropdown from "../../Components/LanguageDropdown ";
import { useSelector } from "react-redux";

const Header = ({ loginData }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  // const isMobile = useSelector((store) => store.user.isMobile);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const toggleSidebar = () => {
    dispatch(setIsCollapse());
    //dispatch(setIsMobile())
  };

  const toggleSearchBar = () => {
    setActiveDropdown((prev) => (prev === "search" ? null : "search"));
  };

  const toggleUserDropdown = () => {
    setActiveDropdown((prev) => (prev === "user" ? null : "user"));
  };

  const toggleLanguageDropdown = () => {
    setActiveDropdown((prev) => (prev === "language" ? null : "language"));
  };

  const handleLogout = () => {
    console.log("Logging out...");
    navigate("/");
  };

  return (
    <div className="w-full h-20 flex justify-between items-center p-5 bg-color-dimligt-blue shadow-md">
      <div className="flex items-center space-x-3">
        {/* Menu Button - Hidden only on md screens */}
        <button
          className="p-2 outline-none hidden md:block"
          onClick={toggleSidebar}
        >
          <RiMenuFold3Line2 className="w-6 h-6" />
        </button>
        <span className="text-xl font-semibold">{t("header_title")}</span>
      </div>

      {/* Right Side - Icons & User Profile */}
      <div className="flex items-center space-x-4">
        <div className="w-10 h-10 flex justify-center items-center">
          <button className="z-50" onClick={toggleLanguageDropdown}>
            <LanguageDropdown />
          </button>
        </div>

        {/* Search Bar */}
        <div className="relative">
          <button
            onClick={toggleSearchBar}
            className="flex items-center justify-center w-10 h-10"
          >
            <RiSearchLine className="w-5 h-5 text-gray-600" />
          </button>
          {activeDropdown === "search" && (
            <div className="h-16  flex justify-center items-center absolute top-16 right-0 bg-white shadow-md rounded-lg w-80 transition-transform transform scale-95 origin-top-right z-10">
              <SearchBarComp />
            </div>
          )}
        </div>

        {/* Notifications Icon */}
        <div className="relative w-10 h-10 flex justify-center items-center">
          <FaRegBell className="w-5 h-5 text-gray-500" />
          <span className="absolute top-0 right-0 text-xs text-white bg-red-600 rounded-full w-4 h-4 flex items-center justify-center">
            4
          </span>
        </div>

        {/* User Profile */}
        <div className="flex items-center space-x-4">
          <img
            src={User}
            alt="User"
            className="rounded-full w-10 h-10 cursor-pointer"
            onClick={toggleUserDropdown}
          />
          <span className="text-color-black font-roboto font-semibold">
            {loginData.user_type_name}
          </span>

          {activeDropdown === "user" && (
            <div className="absolute right-10 top-20 mt-2 w-48 bg-white border rounded shadow-lg z-10">
              <ul className="list-none p-2">
                <li
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={handleLogout}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
