import { createBrowserRouter } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Error from "../Utils/Error";
// import ProtectRoute from "./components/ProtectRoute";
import Body from "../Body";
import Dashboard from "../Pages/Dashborad/Dashborad";
import Profile from "../Components/UserManagement/Profile/Profile";
import User from "../Components/UserManagement/User/User";
import Institution from "../Components/Settings/Insititution/Insititution";
import AddInsititution from "../Components/Settings/Insititution/AddInsititution";
import ViewInsititution from "../Components/Settings/Insititution/ViewInsititution";
import Branch from "../Components/Settings/Branch/Branch";
import UserForm from "../Components/UserManagement/User/UserForm";
import EditInsititution from "../Components/Settings/Insititution/EditInsititution";
import AddUser from "../Components/UserManagement/User/AddUser";
import Department from "../Components/Settings/Department/Department";
import AddDepartment from "../Components/Settings/Department/AddDepartment";
import ViewDepartment from "../Components/Settings/Department/ViewDepartment";
import EditDepartment from "../Components/Settings/Department/EditDepartment";
import AddBranch from "../Components/Settings/Branch/AddBranch";
import ViewBranch from "../Components/Settings/Branch/ViewBranch";
import EditBranch from "../Components/Settings/Branch/EditBranch";
import DeleteDepartment from "../Components/Settings/Department/DeleteDepartment";
import DeleteBranch from "../Components/Settings/Branch/DeleteBranch";
import Service from "../Components/Settings/Service/Service";
import AddService from "../Components/Settings/Service/AddService";
import ViewService from "../Components/Settings/Service/ViewService";
import EditService from "../Components/Settings/Service/EditService";
import DeleteService from "../Components/Settings/Service/DeleteService";
import Token from "../Components/TokenManagement/Token";

import ViewUser from "../Components/UserManagement/User/UserView";
import LanguageSettings from "../Components/Settings/Language/LanguageSettings";
// import EditProfile from "./components/EditProfile";
// import ProfileView from "./components/ProfileView";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: "/body",
    element: <Body />,
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "tokenmanagement",
        element: <Token />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "user",
        element: <User />,
        errorElement: <Error />,
      },

      {
        path: "createUser",
        element: <UserForm />,
        errorElement: <Error />,
      },
      {
        path: "adduser",
        element: <AddUser />,
        errorElement: <Error />,
      },
      {
        path:"viewuser",
        element:<ViewUser/>,
        errorElement:<Error/>
      },
      {
        path: "institution",
        element: <Institution />,
      },

      {
        path: "addinsititution",
        element: <AddInsititution />,
      },
      {
        path: "viewinsititution",
        element: <ViewInsititution />,
      },
      {
        path: "editinsititution/:id",
        element: <EditInsititution />,
      },

      {
        path: "department",
        element: <Department />,
      },

      {
        path: "adddepartment",
        element: <AddDepartment />,
      },
      {
        path: "viewdepartment",
        element: <ViewDepartment />,
      },
      {
        path: "editdepartment/:id",
        element: <EditDepartment />,
      },
      {
        path: "deletedepartment/:id",
        element: <DeleteDepartment />,
      },
      {
        path:"language",
        element:<LanguageSettings/>
      },
      {
        path: "service",
        element: <Service />,
      },

      {
        path: "addservice",
        element: <AddService />,
      },
      {
        path: "viewservice",
        element: <ViewService />,
      },
      {
        path: "editservice/:id",
        element: <EditService />,
      },
      {
        path: "deleteservice/:id",
        element: <DeleteService />,
      },

      {
        path: "branch",
        element: <Branch />,
      },
      {
        path: "addbranch",
        element: <AddBranch />,
      },
      {
        path: "viewbranch",
        element: <ViewBranch />,
      },
      {
        path: "editbranch/:id",
        element: <EditBranch />,
      },
      {
        path: "deletebranch/:id",
        element: <DeleteBranch />,
      },
    ],
  },
]);

export default appRouter;
