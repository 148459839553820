import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BranchForm = ({ selectedBranch, onSubmit, isSubmitting }) => {
  const [branchName, setBranchName] = useState("");
  const [BranchAddress, setBranchAddress] = useState("");
  const [branchEmail, setBranchEmail] = useState("");
  const [branchPhone, setBranchPhone] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedBranch) {
      setBranchName(selectedBranch.name || "");
      setBranchAddress(selectedBranch.address || "");
      setBranchEmail(selectedBranch.email || "");
      setBranchPhone(selectedBranch.mobile || "");
    } else {
      setBranchName("");
      setBranchEmail("");
      setBranchAddress("");
      setBranchPhone("");
    }
  }, [selectedBranch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      branch_name: branchName,
      branch_email: branchEmail,
      branch_mobile: branchPhone,
      branch_address: BranchAddress,
      status: 1,
      inst_id: 1,
      branch_id: selectedBranch?.id,
    };
    console.log("Submitting payload:", payload);
    onSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="flex flex-col p-10 font-roboto w-full sm:w-3/4 flex-nowrap">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="instName" className="mb-2">
              {t("BranchPage.branch_name")}
            </label>
            <input
              type="text"
              id="branchName"
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.branch_name_placeholder")}
              required
            />
          </div>
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="hoAddress" className="mb-2">
              {t("BranchPage.Branch_address")}
            </label>
            <input
              type="text"
              id="branchAddress"
              value={BranchAddress}
              onChange={(e) => setBranchAddress(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.address_placeholder")}
              required
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="branchEmail" className="mb-2">
              {t("BranchPage.email")}
            </label>
            <input
              type="email"
              id="branchEmail"
              value={branchEmail}
              onChange={(e) => setBranchEmail(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.email_placeholder")}
              required
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
          <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
            <label htmlFor="branchPhone" className="mb-2">
              {t("BranchPage.phone")}
            </label>
            <input
              type="tel"
              id="branchPhone"
              value={branchPhone}
              onChange={(e) => setBranchPhone(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.phone_placeholder")}
              required
            />
          </div>
        </div>

        <div className="fixed bottom-5 left-auto right-0 flex justify-center gap-2 px-4">
          <button
            type="submit"
            className="bg-color-light-blue text-color-white py-2 px-4 rounded hover:bg-color-blue"
            disabled={isSubmitting}
          >
            {isSubmitting ? t("BranchPage.submitting") : t("BranchPage.submit")}
          </button>
          <button
            type="button"
            onClick={() => navigate("/body/branch")}
            className="bg-color-light-blue text-color-white py-2 px-6 rounded hover:bg-color-blue"
          >
            {t("BranchPage.back")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default BranchForm;
