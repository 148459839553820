import React from "react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./Redux/Store";
import appRouter from "./Router/Router";
import "./App.css";
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <Provider store={Store}>
       <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />      
      <div className="App">
        <RouterProvider router={appRouter} />
      </div>
    </Provider>
  );
}

export default App;
