import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyValueDisplay from "../../KeyValue";

const ViewUser = () => {
    const selectedUserData = useSelector((state) => state.userManagement.selecteduserView);

  console.log("selectedInstitution", selectedUserData);

  const navigate = useNavigate();

  if (!selectedUserData) {
    return (
      <div className="font-roboto">No account sub type data available.</div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center font-roboto">
        <h2 className="flex p-5 font-bold">View User</h2>
        <div className="flex justify-end p-5">
          <button
            onClick={() => navigate("/body/user")}
            className="bg-color-light-blue text-color-white px-4 py-2 rounded"
          >
            Back
          </button>
        </div>
      </div>

      <div className="border w-[78] h-auto mx-5 p-4 rounded shadow-md font-roboto bg-color-white">
        <KeyValueDisplay data={selectedUserData} />
      </div>
    </div>
  );
};

export default ViewUser;
